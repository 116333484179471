<template>
  <div class="about bx">
    <h1>This is an about page</h1>
    <div class="content"></div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="less" scoped>
  .content{
    width: 100%;
    height: 500px;
    background-color: red;
  }
</style>
